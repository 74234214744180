export const app = {
    home: '首页',
    developer: '开发者',
    develop_doc: '开发者文档',
    develop_doc_tip: '开始构建Callchain应用',
    github: 'Github',
    github_tip: '访问我们的Github社区',
    ecosystem: '生态系统',
    explorer: '浏览器',
    explorer_item: '浏览器',
    explorer_item_tip: '查看Callchain链上信息',
    wallet: '钱包',
    wallet_item1: 'CallWallet',
    wallet_item1_tip: 'CallWallet网页钱包',
    wallet_item2: 'PancakeSwap',
    wallet_item2_tip: 'PancakeSwap上CALL和BUSD之间交易',
    bridge: '跨链桥',
    bridge_item: 'CallBridge',
    bridge_item_tip: 'Callchain和BSC之间的跨链桥',

    media: '媒体',
    media_item1: 'Twitter',
    media_item1_tip: '关注我们的Twitter',
    media_item2: 'Medium',
    media_item2_tip: '关注我们的Medium',
    media_item3: 'Telegram',
    media_item3_tip: '加入我们的Telegram社区',
    media_item4: 'Discord',
    media_item4_tip: '加入我们的Discord社区',
};

export const home = {
    mission: '使命',
    mission_tip: '每个人都可以发行个人代币',
    start: '立即开始',
    why_call: '为什么选择 Callchain',
    why_call_tip1: 'Callchain是基于区块链技术的个人价值平台，实现便捷化、智能化的IP功能，提供高性能的IP代币平台。',
    why_call_tip2: 'Callchain的目标是实现个人的创造力、影响力、知识水平的价值化，这种价值通过Callchain定量化，可以确权、定价、交易。基于此价值，每个人都能够获取基本的收入，人们的未来不再是为了生存而工作，人们可以去做自己热爱的、对社会有价值的、创造个人价值的工作。',
    advantage: '我们的特点',
    advantage1: '一键发币。原生区块链系统支持多资产发行，不需要编写智能合约，钱包界面一键发行个人的资产代币。',
    advantage2: '信任账户模型。基于IP主和粉丝之间信任关系构建的账户模型，IP主的热度是基于粉丝信任，信任越多IP主约有价值。',
    advantage3: '原生去中心化交易。个人代币可以和CALL交易，也可以和其他个人代币交易，币币交易无限制，不需要其他额外开发。',
    advantage4: '智能合约支持。实现基于Lua的可配置的智能合约支持，目前支持配置账户功能，在用户进行对有配置智能合约的账号进行转账时会触发。',
    milestone: '我们的里程碑',
    milestone_tip: '与Callchain一起创造历史',
    milestone1: '主网发布上线',
    milestone2: '一键发币上线',
    milestone3: '一键发卡上线',
    milestone4: '源码全开源',
    milestone5: '链上返佣上线',
    milestone6: '敬请期待',
    community: '加入Callchain社区',
    community_tip1: 'Callchain是一个全球性的、去中心化的公链，拥有来自世界各地的开发人员和社区成员。',
    community_tip2: '加入我们的讨论，来获取最新消息和公告。',
    subscription: '订阅我们获得更多消息',
    subscription_tip: '我们将向您发送有关Callchain的最新信息',
    subscribe: '订阅'
};

export const footer = {
    about: '关于Callchain',
    notice: '公告',
    warn: '风险提示',
    tools: '开发工具',
    doc: '文档',
    github: 'Github',
    contact: '联系我们',
    support: '技术支持',
    faq: 'FAQ'
};