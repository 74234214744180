<template>
  <v-container id="home" class="px-6">
    <div class="d-flex align-center justify-center">
      <h1>Privacy Policy</h1>
    </div>
    <div class="d-flex px-12 pt-6">
      <span class="txt">Thank you for choosing to be part of our Callchain community, doing business as Callchain (“Callchain“, “we“, “us“, “our“). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at contact@callchain.cc.</span>
    </div>

    <div class="d-flex px-12 pt-6">
      <span class="txt">When you visit our website <a href="https://www.callchain.cc/">https://www.callchain.cc/</a> (the “Website“), and more generally, use any of our services (the “Services“, which include the Website), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</span>
    </div>

    <div class="d-flex px-12 pt-6">
      <span class="txt">This privacy notice applies to all information collected through our Services (which, as described above, includes our Website), as well as, any related services, sales, marketing or events.</span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            
            <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>


            Personal information you disclose to us
We collect personal information that you provide to us.
We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Website (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.
The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make and the products and features you use. The personal information we collect may include the following:
Personal Information Provided by You. We collect email addresses; and other similar information.
All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.

Information collected from other sources
We may collect limited data from public databases, marketing partners, and other outside sources.
In order to enhance our ability to provide relevant marketing, offers and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, as well as from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs and custom profiles, for purposes of targeted advertising and event promotion.
        </span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>2. HOW DO WE USE YOUR INFORMATION?</h2>


            We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
We use the information we collect or receive:

To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Website, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS?” below).
Deliver targeted advertising to you. We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.
        </span>
    </div>


    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>

            We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
We may process or share your data that we hold based on the following legal basis:
Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
More specifically, we may need to process your data or share your personal information in the following situations:
Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        </span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

            We may use cookies and other tracking technologies to collect and store your information.
We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
        </span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

            We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 6 months.
When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

            We aim to protect your personal information through a system of organizational and technical security measures.
We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.

        </span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>

            We do not knowingly collect data from or market to children under 18 years of age.
We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Website. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at contact@callchain.cc.

</span>
    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

            In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.
If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Website. To opt-out of interest-based advertising by advertisers on our Website visit <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.

</span>
    </div>

     <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

        </span>
     </div>

    <div class="d-flex px-12 pt-6">
            <span class="txt">
                <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>


Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
If you are under 18 years of age, reside in California, and have a registered account with the Website, you have the right to request removal of unwanted data that you publicly post on the Website. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Website, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).

    </span>

    </div>

    <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>11. DO WE MAKE UPDATES TO THIS NOTICE? </h2>

Yes, we will update this notice as necessary to stay compliant with relevant laws.
We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
        </span>
     </div>

     <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </h2>

If you have questions or comments about this notice, you may email us at contact@callchain.cc</span>
     </div>

     <div class="d-flex px-12 pt-6">
        <span class="txt">
            <h2>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>


Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request to our contact email.
</span>
     </div>

    
  </v-container>
</template>

<script>


export default {
  name: "PrivacyPolicy",
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
  #home {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .txt {
        font-size: 20px;
    }

    @media screen and (max-width: 1000px) {
    }
  }
</style>